<template>
    <div>
        <base-header type="gradient-hyb-cyan" class="pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
          <div class="row mb-3">
            <div class="col-xl-8 mb-5 mb-xl-0">
              <card type="default" header-classes="bg-transparent">
                <div slot="header" class="row align-items-center">
                  <div class="col">
                    <h6 class="text-light text-uppercase ls-1 mb-1">Überblick</h6>
                    <h5 class="h3 text-white mb-0">Briefe</h5>
                  </div>
                </div>
                <line-chart :height="250" ref="bigChart" :chart-data="leftChart.chartData"
                  :extra-options="leftChart.extraOptions">
                </line-chart>
              </card>
            </div>
          </div>
            
          <div class="row">
            <div class="col">
                <dashboard-table
                :title="tableTitle" 
                :status="status"
                :orders="orders"
                @paginate="handlePaginate"
                @search="handleSearch"
                @onDateRangeUpdate="handleDateRangeUpdate"
              ></dashboard-table>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import DashboardTable from './Tables/DashboardTable'
import { mapState } from 'vuex'
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    DashboardTable,
    LineChart
  },
  data() {
    return {
      leftChart: {
        allData: [],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
    }
  },
  computed: {
    ...mapState('order', [
      'orders',
      'dashboardAnalytics'
    ]),
    status () {
      return 'abgerechnete';
    },
    tableTitle () {
      let title = 'Dashboard'
      return title
    },
    orderQuery () {
      const params = {
        query: {
          status: ['printed', 'billed']
        },
        includeManaged: true
      }
      return params
    }
  },
  watch: {
    status() {
      this.resetOrders()
      this.fetchOrders()
    }
  },
  mounted () {
    this.resetOrders()
    this.fetchOrders()
    this.fetchDashboardAnalytics()
      .then(() => {
        this.initCharts(0);
      })
  },
  methods: {
    handleDateRangeUpdate (val) {
      const { startDate, endDate } = val
      this.fetchOrders(startDate, endDate)
    },
    initCharts(index) {
      // Init left chart
      this.leftChart.chartData = {
        datasets: [
          {
            label: 'Printed',
            data: this.dashboardAnalytics.graphUberblickBriefe.map(d => d.count)
          }
        ],
        labels: this.dashboardAnalytics.graphUberblickBriefe.map(d => moment(d.date).format('DD.MM'))
      }
      this.leftChart.activeIndex = index
    },
    fetchDashboardAnalytics () {
      return this.$store.dispatch('order/fetchDashboardAnalytics')
    },
    fetchOrders (startDate=null, endDate=null) {
      const updatedQuery = {...this.orderQuery}

      if (startDate && endDate) {
        updatedQuery.isPrintedBetween = [
          startDate,
          endDate
        ]
      }
      this.$store.dispatch('order/fetchOrders', updatedQuery)
    },
    resetOrders () {
      this.$store.dispatch('order/resetOrders')
    },
    handlePaginate (pageNum) {
      this.$store.dispatch('order/setPageNum', pageNum)
      this.$store.dispatch('order/fetchOrders', this.orderQuery)
    },
    handleSearch (term) {
      this.$store.dispatch('order/fetchOrders', {
        ...this.orderQuery,
        search: term
      })
    }
  }
};
</script>

<style></style>
